function InvalidEmail(textbox) {
    if (textbox.value == '') {
        textbox.setCustomValidity('Bạn phải điền vào ô nhập này');
    }
    else if (textbox.validity.typeMismatch) {
        textbox.setCustomValidity('Email không chính xác.');
    }
    else {
        textbox.setCustomValidity('');
    }
    return true;
}

function InvalidTextbox(textbox) {
    if (textbox.value == '') {
        textbox.setCustomValidity('Bạn phải điền vào ô nhập này');
    }
    else {
        textbox.setCustomValidity('');
    }
    return true;
}

function ContactSubmit() {
    const name = $('#name').val()
    const email = $('#email').val()
    const phone = $('#phone').val()
    const note = $('#note').val()
    const data = {
        "name": name,
        "email": email,
        "phone": phone,
        "note": note
    }
    $.ajax({
        url: "http://localhost:3002/contact",
        data: JSON.stringify(data),
        contentType: "application/json",
        method: "POST"
    }).done(function (resp) {
        alert("Cảm ơn bạn đã liên hệ. Chúng tôi sẽ liên lạc cho bạn qua email hoặc số điện thoại.")
        $('#name').val('')
        $('#email').val('')
        $('#phone').val('')
        $('#note').val('')
    }).fail(function () {
        alert("Có lỗi xảy ra, xin hãy thử lại sau hoặc gọi điện cho chúng tôi.")
    })
}
